#log{

	font-size: 12px;

}

#log img{ height: 23px !important; }


.nenhumlog{ text-align: center; font-size: 16px; margin-top: 16px; }

.logwarn{

    background-color: rgb(224, 177, 76) !important;
    color: #f7f7f7 !important;

}

.logerror{

	background-color: rgb(193, 44, 11) !important;
    color: #fff !important;

}

.logdiv{ margin-top: 5px !important; margin-bottom: 0px; }