.boxAsset{

	height: 40px;
	width: 40px; 
	vertical-align: middle;
    display: table-cell;
    cursor:pointer;
    text-align: center;

}

.boxAsset img { max-height: 35px; }


.goog-menuitem-content img{

	max-width: 90px !important;

}