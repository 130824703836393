.studio_options{

	position: absolute; 
	left: calc(10%);
  background-color: #272727;
  border-radius: 0px 0px 10px 10px; 
  box-shadow: 0px 1px 6px -4px #000;
  height: 52px;

}

.studio_options .option{
	
	cursor: pointer;
	height: 60px;
	width: 50px;
  top: -8px;
	/*background-color: #fff;*/
  margin: 0px 2px 0px 2px;
	position: relative;
  text-align: center;
  border-radius: 0px 0px 40px 40px;
  /*box-shadow: 0px 2px 5px -1px #000;*/

}

.hidden_div{ display: none !important; }

.studio_options .option img{ height: 32px; margin-top: 15px; }

.studio_2{ margin-top: 100px; }

.splitter{ -webkit-flex: 0 0 auto; flex: 0 0 auto; width: 18px; background: url(/media/vsizegrip.png) center center no-repeat #535353; min-height: 200px; cursor: col-resize; }

.blocklySvg{ background-color: #414a50 !important;  }

.blocklyScrollbarHandle{ fill:#353c42; }

.blocklyMainBackground{ stroke: #c6c6c600; } 

.blocklyToolboxDiv{ background-color: #282f34; }

.blocklyFlyoutBackground{ fill: #22262c; fill-opacity: 1; }

.blocklyTreeLabel{ color: #fff; }

.blocklyTreeRow:not(.blocklyTreeSelected):hover{ background-color: #414a50; }

.headerLeftStudio{ display: -webkit-flex; display: flex; }

.optionHeaderStudio{

  width: 50%; 
  text-align: center; 
  background-color: #3b4146;
  padding: 5px;
  cursor: pointer;

}

.btfontsize{

    background-color: transparent;
    color: #fff;
    border: navajowhite;
    padding: 0;
    
}

.btfontsize:focus { outline: none; }

.optionHeaderStudio .fontsize{

  display: none;

}

.optionHeaderStudio.ativo .fontsize{

  display: block !important;

}


.headerLeftStudio .ativo{ background-color: #282f34; }

.optionHeaderStudio font{ 

  color: #fff;
  font-weight:600;

}

.optionHeaderStudio.ativo{

  background-color: #282f34;

}

.optionHeaderAsset{

    color: #fff;
    font-weight: 400;
    cursor: pointer;

}

 

.optionHeaderAsset .fa-folder-open{ display: none; }
 

.optionHeaderAsset.ativo .fa-folder-open{
 
    display: inline !important;
 
    display: initial !important;
    margin-right: 3px;
}
 

.optionHeaderAsset.ativo .fa-folder{

    display: none !important;
     
}



#divIframePhaser{ margin-top: 10px !important; }

.headerLeftStudio2{ 
  
  display: -webkit-flex; 
  
  display: flex; 
  box-shadow: 0px 7px 12px -11px #000;

}

.optionHeaderStudio2{

  width: 50%;
  text-align: center;
  background-color: #3b4146;
  padding: 0px;
  cursor: pointer;
  font-size: 12px;
  margin-top: 10px;

}

.headerLeftStudio2 .ativo{ background-color: #282f34; }

.optionHeaderStudio2 font{ 

  color: #fff;
  font-weight:600;

}

.optionHeaderStudio2.ativo{

  background-color: #4b5054;

}

.alterCanvasSize{

    color: #576269;
    background-color: #ffffff00;
    cursor: pointer;
    border: navajowhite;
    padding: 1px;
    border-radius: 10px;
    text-align: center;
    outline: none;

}
.alterCanvasSize:focus{outline:0;}

.rc-tooltip.rc-tooltip-zoom-enter,
.rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}
.rc-tooltip-zoom-enter,
.rc-tooltip-zoom-appear {
  opacity: 0;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
          animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-tooltip-zoom-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
          animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  -webkit-animation-name: rcToolTipZoomIn;
          animation-name: rcToolTipZoomIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  -webkit-animation-name: rcToolTipZoomOut;
          animation-name: rcToolTipZoomOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@-webkit-keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}
@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}
.rc-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.5;
  opacity: 0.9;
  pointer-events: none;
}
.rc-tooltip-hidden {
  display: none;
}
.rc-tooltip-placement-top,
.rc-tooltip-placement-topLeft,
.rc-tooltip-placement-topRight {
  padding: 5px 0 9px 0;
}
.rc-tooltip-placement-right,
.rc-tooltip-placement-rightTop,
.rc-tooltip-placement-rightBottom {
  padding: 0 5px 0 9px;
}
.rc-tooltip-placement-bottom,
.rc-tooltip-placement-bottomLeft,
.rc-tooltip-placement-bottomRight {
  padding: 9px 0 5px 0;
}
.rc-tooltip-placement-left,
.rc-tooltip-placement-leftTop,
.rc-tooltip-placement-leftBottom {
  padding: 0 9px 0 5px;
}
.rc-tooltip-inner {
  padding: 8px 10px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  background-color: #373737;
  border-radius: 6px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
  min-height: 34px;
}
.rc-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  bottom: 4px;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #373737;
}
.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 15%;
}
.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  left: 4px;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #373737;
}
.rc-tooltip-placement-right .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  right: 4px;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #373737;
}
.rc-tooltip-placement-left .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  top: 4px;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #373737;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 15%;
}

.tableAsset li {

  list-style-type: none; 

}

#phaserTableBaixo{

  margin-right: 18px;

}

#phaserTableBaixo th {

  color: #fff;
  font-size: 12px;
  color: #fff;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
  border: 0px solid #000;
  
}

textarea.form-control:focus, 
input.form-control:focus, 
input[type=text]:focus, 
input[type=password]:focus, 
input[type=email]:focus, 
input[type=number]:focus, 
[type=text].form-control:focus, 
[type=password].form-control:focus, 
[type=email].form-control:focus, 
[type=tel].form-control:focus, 
[contenteditable].form-control:focus {
  box-shadow: 0px 0px 0px 0px #ddd;
}

.input_baixo.form-control{

  background-color: #282f34 !important;
  color: #ffffff !important;
  border:none !important;
  font-size: 14px;
  font-weight: 300;
}

.tobd_n i {

  color: #fff;
  
}

.input_baixo{

  height: 20px;
  padding-left: 0px;
  border-radius: 0px;

}

 

.ckbx-style-14 label{

  height: 20px !important;
  background: #3330 !important;
  width: 80px;

}

.ckbx-style-14 label:after{
  font-size: 10px !important;
  width: 40% !important;
}

.ckbx-style-14 label:before{
  font-size: 10px !important;
  width: 40% !important;
  left: 40% !important;
}

.ckbx-style-14{

  padding: 0px 0px 0px 0px !important;
  margin-top: 0px !important;
  width: 0px !important;

}

.tobd_n{
 
    border: 0px solid #000 !important;
    border-collapse: separate !important;
    border-spacing: 4px !important;

}

 

.tobd_n td, .tobd_n th{

  border-top: 1px solid #3b4146 !important;
  border-bottom: 1px solid #3b4146 !important;

}

.tobd_n td{

  padding: 3px;
  vertical-align: top;
  border-top: 1px solid #dee2e6;

}

.bt_tab{

  height: 20px;
  padding: 0px;

}

.rowTitlebaixo{ 

  color:#fff;
  font-size:13px;
  font-weight: 600;

}

.addAlgo{

  float: right;
  font-family: serif;
  font-family: initial;
  font-size: 17px;
  margin-right: 5px;

}


.selectTipoEditor{

  width: 100%;
  height: 20px;
  padding: 0px;
  border: none;
  padding-left: 0px;
  background-color: #282f34;
  color: #fff;

}

.optionDivAsset{ display: none; }
.optionDivAsset.ativo{ display: block !important; }
	.rdTabWrapper{
 
		position: relative;

	}

	.rdTab{ margin-top: 10px !important; }

	.rdTabWrapper ul{

		outline: 0px; 
		display: -webkit-flex; 
		display: flex; max-width: 99%; 
		-webkit-user-select: none; 
		   -moz-user-select: none; 
		    -ms-user-select: none; 
		        user-select: none; 
		padding-right: 35px; 
		margin: 0px; 
		list-style: none; 
		min-width: 95%; 
		overflow: hidden;

	}

	.rdTabBeforeTitle{ 

		position: absolute; 
		top: 8px; 
		left: -8px; 
		z-index: 2; 


	}

	.react-draggable{

		touch-action: none; 
		max-width: 175px; height: 26px; 
		position: relative; 
		padding-right: 24px; 
		-webkit-transform: translate(0px, 0px); 
		        transform: translate(0px, 0px); 
		background-image: linear-gradient(rgb(69, 69, 69), rgb(51, 51, 51)); 
		font-size: 11px; 
		min-width: 0px; 
		margin-left: 30px; 
		padding-left: 15px; 
		-webkit-flex: 1 1; 
		        flex: 1 1;
	    display: flow-root;
	    list-style: none;

	}

	.rdTabTitle{

		overflow: hidden; 
		color: #fff; 
		width: 90%; 
		margin-top: 4px; 
		text-overflow: ellipsis; 
		cursor: default; 
		text-align: center; 
		float: left; 
		white-space: nowrap;
		text-align: left;

		position: absolute;
	    z-index: 99999;

	    font-size: 12px;
	    text-shadow: 0px 1px #000000;
	   	font-weight: 600;

	}
	
	.rdTabAfterTitle{ 

		position: absolute; 
		top: 8px; 
		right: 16px; 
		z-index: 2; 

	}

	.rdTabCloseIcon{ 

		border-radius: 8px; 
		width: 16px; 
		margin-top: 5px; 
		height: 16px; 
		position: absolute; 
		opacity: 0.2; 
		text-shadow: rgb(255, 255, 255) 0px 1px 0px; 
		font: 16px/1em arial, sans-serif; 
		cursor: pointer; 
		z-index: 999; 
		text-align: center; 
		right: -2px; 
		text-decoration: none; 
		-webkit-filter: none; 
		        filter: none; 

	}

	.rdTabBefore{ 

		border-radius: 7.5px 0px 0px; 
		width: 25px; 
		left: -14px; 
		height: 26px; 
		position: absolute; 
		-webkit-transform: skewX(0deg); 
		        transform: skewX(0deg); 
		box-shadow: rgb(72, 72, 72) 1px 1px 0px inset, rgba(0, 0, 0, 0.1) -4px 0px 4px; 
		background-image: linear-gradient(rgb(69, 69, 69), rgb(51, 51, 51)); 
		top: 0px; 

	}

	.rdTabAfter{

		border-radius: 0px 7.5px 0px 0px; 
		width: 25px; 
		height: 26px; 
		position: absolute; 
		-webkit-transform: skewX(30deg); 
		        transform: skewX(30deg); 
		box-shadow: rgb(72, 72, 72) -1px 1px 0px inset, rgba(0, 0, 0, 0.1) 4px 0px 4px; 
		background-image: linear-gradient(rgb(69, 69, 69), rgb(51, 51, 51)); 
		top: 0px; 
		right: -14px;

	}

	.rdTabAddButton{

		cursor: pointer; 
		font-size: 20px; 
		text-shadow: rgb(255, 255, 255) 0px 1px 0px; 
		position: relative; 
		width: 25px; 
		height: 26px; 
		margin-left: 20px; 
		z-index: 2;

	}

	.rdTabBarAfter{

		left: 0px; 
		height: 5px; position: 
		absolute; top: 26px; 
		background-color: rgb(34, 34, 34); 
		pointer-events: none; 
		border-bottom: 1px solid rgb(17, 17, 17); 
		z-index: 2; 
		right: 0px;

	}

	.react-draggable.ativo{

		    background-image: linear-gradient(rgb(84, 93, 99), rgb(65, 74, 80));

	}

	.react-draggable.ativo .rdTabBefore{ 

		background-image: linear-gradient(rgb(84, 93, 99), rgb(65, 74, 80)); 
		box-shadow: 0px 0px 0px 0px;


	}

	.react-draggable.ativo .rdTabAfter{

		background-image: linear-gradient(rgb(84, 93, 99), rgb(65, 74, 80)); 
		box-shadow: 0px 0px 0px 0px;	

	}

	.react-draggable.ativo{ z-index: 9 !important; }
	

	#selectToolBox{

		position: absolute;
		text-align: center;
		width: 122px;
		border: none;
	    height: 26px;
	    padding: 0px 0px 0px 9px;
	    top: 10px;
        background-image: linear-gradient(rgb(67, 67, 67), rgb(40, 47, 52));
	    border-radius: 0px 10px 0px 0px;
	    background-color: #525b61;
	    color: white;
	    box-shadow: 0px 0px 7px -3px #000;

	}


	.maisFile{

		position: absolute;
	    float: right;
	    color: #fff;
	    font-size: 24px;
	    left: 100%;
	    font-weight: 600;
	    top: 12px;
	    text-shadow: 1px 1px 1px #000;

	}

	.tituloProjeto{

		color:#fff;
		font-size: 17px;
		font-weight: 500;
		margin-top: 10px;

	}






























	
.boxAsset{

	height: 40px;
	width: 40px; 
	vertical-align: middle;
    display: table-cell;
    cursor:pointer;
    text-align: center;

}

.boxAsset img { max-height: 35px; }


.goog-menuitem-content img{

	max-width: 90px !important;

}
#log{

	font-size: 12px;

}

#log img{ height: 23px !important; }


.nenhumlog{ text-align: center; font-size: 16px; margin-top: 16px; }

.logwarn{

    background-color: rgb(224, 177, 76) !important;
    color: #f7f7f7 !important;

}

.logerror{

	background-color: rgb(193, 44, 11) !important;
    color: #fff !important;

}

.logdiv{ margin-top: 5px !important; margin-bottom: 0px; }
.customConfigAcer{

    position: absolute;
    z-index: 9999;
    background-color: #000;
    top: calc(100% - 20px);
    left: calc(100% - 190px);
    padding: 0px 5px 0px 5px;
    border-radius: 5px 5px 0px 0px;
    box-shadow: 0px -1px 6px -3px #000; 

}

.sizeFontAce{

	color:#fff;
	font-weight: 600;

}

.iconSizeFontAce{

	color:#fff;

}

.selectThemeAce{

	padding: 0px;
	height: 20px;
	border: none;
	margin-left: 10px;
	padding-right:5px; 
	padding-left:5px;
	background-color: #ffffff00;
	color: #fff;
	
}
.gameselect{

    width: 100%;
    height: 85px;
    background-color:#232223;
    color: #969696;

    padding: 0px;
    margin-bottom: 15px;
    box-shadow: 0px 3px 8px -8px #000;

}

body{

    background-color: #1b191b;
    
}

header{

    background: #232223 !important;
    height: 50px;

}

nav#main-nav>ul>li>button{

    padding: 0px 0px !important;
    line-height: 50px !important;

}

.logotype img{

    top:0px !important;
    height: 74px !important;
    margin-top: -3px !important;

}

#logo{

    width: 235px;
    height: 70px;
    box-shadow: 0px 2px 7px -5px #000;
    background-image: linear-gradient(180deg, #232223 -106%, #d3333b 121%);

}


.buttonEnterGame{

    margin-top: 24px; 
    font-size: 15px;
    font-weight: lighter;
    background-color: #464646;
    border:none !important;
    box-shadow: 0px 0px 0px 0px !

}

.buttonEnterGame:hover{

    background-color: #5f5c5c;
    border:none !important;
    box-shadow: 0px 0px 0px 0px !
    
}

.projectNamePrin{}

.projectNamePrin2{

    margin-top:18px;
    color:#fff;
    font-weight: 500; 

}


.btn {
    position: relative;
    text-transform: none;
    transition: all .15s ease;
    letter-spacing: .025em;
    font-size: .875rem;
    will-change: transform;
}



.btn {
    display: inline-block;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
 
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control {
      
    font-size: 1rem;
    line-height: 1.5;
    color: #8898aa;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
 
}

.div_edit_block{

    background-color: #232223;
    margin-bottom: 10px;  
    color:#fff;
    padding:10px;
    box-shadow: 0px 2px 10px -9px #000;

}

.div_edit_block div{ padding-top: 2px; }

.div_edit_block_modal{
    
    z-index: 9999;
    height: 25px; 

}

.audioAsset .rhap_progress-section{

    display: none;

}

.audioAsset .rhap_additional-controls{ display:none; }

.audioAsset .rhap_container{ 
    
    padding: 0px !important; 
    box-shadow: 0px 0px 0px 0px !important;
    background-color: #fff0;

}

.audioAsset .rhap_volume-controls{ display: none;}

.audioAsset .rhap_forward-button{ display: none;}

.audioAsset path{ fill: #fff !important;}


.audioAsset .rhap_main-controls{ height: 0px !important; }

.audioAsset svg{
    
    height: 25px !important; 

}

.audioAsset{

    position: relative;
    left: -20px;

}
