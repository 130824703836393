.gameselect{

    width: 100%;
    height: 85px;
    background-color:#232223;
    color: #969696;

    padding: 0px;
    margin-bottom: 15px;
    box-shadow: 0px 3px 8px -8px #000;

}

body{

    background-color: #1b191b;
    
}

header{

    background: #232223 !important;
    height: 50px;

}

nav#main-nav>ul>li>button{

    padding: 0px 0px !important;
    line-height: 50px !important;

}

.logotype img{

    top:0px !important;
    height: 74px !important;
    margin-top: -3px !important;

}

#logo{

    width: 235px;
    height: 70px;
    box-shadow: 0px 2px 7px -5px #000;
    background-image: linear-gradient(180deg, #232223 -106%, #d3333b 121%);

}


.buttonEnterGame{

    margin-top: 24px; 
    font-size: 15px;
    font-weight: lighter;
    background-color: #464646;
    border:none !important;
    box-shadow: 0px 0px 0px 0px !

}

.buttonEnterGame:hover{

    background-color: #5f5c5c;
    border:none !important;
    box-shadow: 0px 0px 0px 0px !
    
}

.projectNamePrin{}

.projectNamePrin2{

    margin-top:18px;
    color:#fff;
    font-weight: 500; 

}


.btn {
    position: relative;
    text-transform: none;
    -webkit-transition: all .15s ease;
    transition: all .15s ease;
    letter-spacing: .025em;
    font-size: .875rem;
    will-change: transform;
}



.btn {
    display: inline-block;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
 
    line-height: 1.5;
    border-radius: .25rem;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control {
      
    font-size: 1rem;
    line-height: 1.5;
    color: #8898aa;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
 
}

.div_edit_block{

    background-color: #232223;
    margin-bottom: 10px;  
    color:#fff;
    padding:10px;
    box-shadow: 0px 2px 10px -9px #000;

}

.div_edit_block div{ padding-top: 2px; }

.div_edit_block_modal{
    
    z-index: 9999;
    height: 25px; 

}

.audioAsset .rhap_progress-section{

    display: none;

}

.audioAsset .rhap_additional-controls{ display:none; }

.audioAsset .rhap_container{ 
    
    padding: 0px !important; 
    box-shadow: 0px 0px 0px 0px !important;
    background-color: #fff0;

}

.audioAsset .rhap_volume-controls{ display: none;}

.audioAsset .rhap_forward-button{ display: none;}

.audioAsset path{ fill: #fff !important;}


.audioAsset .rhap_main-controls{ height: 0px !important; }

.audioAsset svg{
    
    height: 25px !important; 

}

.audioAsset{

    position: relative;
    left: -20px;

}